
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";


.base-inner{
    --base-inner-space: 15px;
    padding: 0 var(--base-inner-space);
}

