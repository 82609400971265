
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";
    // no-data-box
.no-data-box{
    @include flex(center,center);
    flex-direction: column;
    padding:10px;
    border-radius: 10px;

    .no-data-icon{
        width: 209px;
        position:relative;
        margin-bottom: 20px;
        @include flex(center,center);
    }

    .no-data-title{
        color: #898989;
        font-size: 16px;
        letter-spacing: 0.1em;
        font-weight: bold;
    }

    &.sty-page{
        margin-top: 21vh;
    }

    &.size-sm{
        .no-data-icon{
            max-width: 110px;
            margin-top: 15px;
        }
        .no-data-title{
            font-size: 18px;
            margin-bottom: 0;
        }
        .no-data-des{
            font-size: 14px;
        }
    }

    @include media(374.98){
        &:not(.size-sm) {
            .no-data-icon{
                max-width: 140px;
            }
        }
    }
}

