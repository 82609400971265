
@import "@design";

.ios, .md{
    .tab-link-bg-hilighter{
        background: var(--f7-segmented-strong-button-active-bg-color);
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 0;
        transition: .3s;
        // transform: scalex(1.1);
    }

    .muki-tabbar{
        &.sty-tiny-space{
            padding: 2px;
        }

        &.sty-overflow-visible{
            overflow: visible;
            .toolbar-inner{
                overflow: visible;
            }
            .tab-link{
                overflow: visible;
            }
        }

        // 其他 
        .muki-num-badge{
            right: 0;
        }
        // 其他 /
    }

    .tab-sty-anchor{
        --f7-toolbar-height: 50px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 8px;
        --f7-toolbar-inner-padding-right: 8px;
        background:#EEF5CC;
        margin-bottom: 15px;

        &.sty-scrollable{
            .tab-link{
                &.button{
                    width: auto;
                }
                &:first-child{
                    margin-left: 15px;
                    border: 1px solid $color-primary;
                }
                &:last-child{
                    margin-right: 15px;
                }
            }
        }

        &.sty-not-scrollable{
            .tab-link{
                margin: 0 5px;
                flex-grow: 1;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }

        .toolbar-inner{
            border-radius: 0;
            background: none;
            overflow-x: auto;
            &::-webkit-scrollbar{
                display:none; /*經測試有效:有simplebar bar時不要顯示原生 scrollbar */
                width: 0;  /* Remove scrollbar space */
                background: transparent;  /* Optional: just make scrollbar invisible */
            }
        }

        .tab-link{
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 15px;
            font-size: 1rem;
            overflow: visible;
            flex-shrink: 0;
            padding: 0 15px;
            font-weight: bold;
            &.tab-link-active{
                background: $color-primary;
                color:#fff;
                position:relative;
                &:after{
                    content: '';
                    display: block;
                    width: 14px;
                    height: 13px;
                    border-radius: 3px;
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%) translateY(-7px) rotate(45deg);
                    background: $color-primary;
                    transform-origin: center;
                }
            }


        }
    }

    .tab-sty-pill{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        border-radius: var(--border-radius);
        overflow: hidden;

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-elegant{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        background: #666666;
        border-radius: var(--border-radius);
        overflow: hidden;

        .toolbar-inner{
            padding-right: 0;
            padding-left: 0;
        }

        .tab-link{
            color:#fff;
            font-size: 1rem;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-elegant-light{
        --f7-toolbar-height: 34px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;
        border: 1px solid #D8D8D8;
        .tab-link{
            color:#000000;
            font-weight: normal;
            &.tab-link-active{
                color: #fff;
            }
        }

        .toolbar-inner{
            background: #F8F8F8;
            padding-left: 0;
            padding-right: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: var(--border-radius);
        }
    }

    .tab-sty-flat{
        --f7-toolbar-height: 36px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;

        .toolbar-inner{
            background: #EAECED;
            padding-left:0;
            padding-right: 0;
        }

        .tab-link{
            color: #666666; 
            margin-left: 0;
            &.tab-link-active{
                font-weight: normal;
                color:#fff; 
                &:first-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        left: 0;
                    }
                }
                &:last-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        right: 0;
                    }
                }
            }
        }

        .tollbar-inner{
            padding: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: 0;
            height: var(--f7-toolbar-height);
            top: 0;
            bottom: 0;
        }


    }

    .tab-sty-flat-light{
        --f7-toolbar-height: 36px;
        --f7-segmented-strong-between-buttons: 10px;
        --f7-button-height: 30px;
        --f7-toolbar-inner-padding-left: 0;
        --f7-toolbar-inner-padding-right: 0;
        --f7-segmented-strong-button-active-bg-color: #{$color-primary};
        --border-radius: calc(var(--f7-toolbar-height) / 2);
        margin-bottom: 15px;

        border-radius: var(--border-radius);
        overflow: hidden;
        border: 1px solid#D8D8D8; 

        .toolbar-inner{
            background: #EAECED;
            padding-left: 0;
            padding-right: 0;
        }

        .tab-link{
            color: #666666; 
            &.tab-link-active{
                font-weight: normal;
                color:#fff; 
                &:first-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        left: 0;
                    }
                }
                &:last-child{
                    ~ .segmented-highlight, .tab-link-bg-hilighter{
                        right: 0;
                    }
                }
            }
        }

        .tollbar-inner{
            padding: 0;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            border-radius: 0;
            height: var(--f7-toolbar-height);
            top: 0;
            bottom: 0;
        }
    }

}

