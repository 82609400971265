
@import "@design";

.p-ride-rate{
    .title-line-diivder{
        margin-bottom: 10px;
    }

    @include mobile-down{
        .border-custom-control{
            .custom-control-icon-default{
                margin-right: 7px;
            }
        }   
        .base-spacer.after-rating{
            height: 10px!important;
        }
    }
}

.ride-summary-box{
    display: flex;
    margin-bottom: 1rem;
    .box-media{
        width: 100px;
        flex-shrink: 0;
        margin-right: calc(11 / 14 * 1rem);
        .user-media{
            width: 100%;
        }
    }

    @include media-up(375){
        .box-media{
            width: rwd-val(375px, 100px, 768px, 140px);
        }
    }

    .box-main{
        flex-grow: 1;
    }
    .box-grid{
        display: flex;
        .grid-col{
            &:first-child{
            }
            &:last-child{
            }
        }
        .grid-divider{
            position: relative;
            @include project-border-left;
            flex-grow: 1;
            min-width: 15px;
            &:after{
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .route-info{
            display: block;
        }
    }

    .route-info{
        margin-bottom: 8px;
        .info-title{
            .info-title-txt{
                font-size: calc(12 / 14 * 1rem);
                letter-spacing: 0;
            }
        }
        .info-content{
            font-size: calc(30 / 14 * 1rem);
        }
        &.small-show{
            .info-content{
                font-size: calc(16 / 14 * 1rem);
                font-weight: bold;
            }
        }

        @include media(375){
            .info-content{
                font-size: rwd-val(320px, 22px, 375px, 30px);
            }
        }
    }

    @include media(375){
        .box-media{
            width: rwd-val(320px, 75px, 375px, 100px);
            margin-right: rwd-val(320px, 5px, 375px, 11px);
        }
    }
}


.reason-block{
    .border-custom-control{
        margin-bottom: 15px;
        color: #666666;
        font-size: calc(16 / 14 * 1rem);
    }
}

.contact-bar{
    display: flex;
    align-items: center;
    font-size: calc(16 / 14 * 1rem);
    flex-wrap: wrap;
    .bar-title{
        color: #666666;
        margin-right: 6px;
    }
    .bar-content{
        color: var(--color-theme);
        a{
            color: inherit;
            font-size: calc(20 / 14 * 1rem);
            font-family: 'Arial';
            letter-spacing: calc(1 / 20 * 1em);
            font-weight: bold;
        }
    }
}

.connected-btn-group{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 2px solid var(--color-theme);
    border-radius: 20px;
    overflow: hidden;
    width: 322px;
    max-width: 86%;
    display: flex;
    z-index: 2;
    .button{
        --f7-button-height: 40px;
        width: 50%;
        font-size: calc(18 / 14 * 1rem);
        @include letter-spacing-center(calc(4.5 / 18 * 1em));
        font-weight: bold;
    }

    .btn-left{
        color:var(--color-theme);
        background:#fff;
        border-radius: 0;
    }

    .btn-right{
        color: #fff;
        background: var(--color-theme);
        border-radius: 0;
    }
}

.map-area{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 239px;
    .map-panel{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .map{
        height:auto!important;
        flex-grow: 1;
    }
}

