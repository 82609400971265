
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";


.p-login{
    .base-spacer.h45{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 45px);
        }
        @include media(320){
            max-height: 20px;
        }
    }
    .base-spacer.h40{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 40px);
        }
        @include media(320){
            max-height: 20px;
        }
    }
    .base-spacer.h30{
        @include media(375){
            max-height: rwd-val(320px, 20px, 375px, 30px);
        }
        @include media(320){
            max-height: 20px;
        }
    }
}

.ios, .md{
    .dialog-text{
        word-break: break-all;
    }
}

.version-text{
    font-size: 14px;
    letter-spacing: 0.1em;
    color: #A4A4A4;
}

.forget-quest{
    padding-left: 1em;
}

