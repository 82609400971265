/* ===============================
              Colors
=============================== */
$color-primary:#fd8908;
$color-sec: #FDF0EA;
$color-trd: #CDC990;
$color-danger: #ff5970;
$color-red: #DF4710;
$color-pure: #FFFFFF;
$color-feature: #0498D8;
$color-tip: #2374AD;

$color-yellow: #FFB10B;

$color-title: #333333;
$color-font: #030303;

$color-blue: #2374AD;

// 文字顏色
$color-font: #333333;
$color-font-gray: #666666;
$color-font-gray2: #ADADAD;


// 邊框
$color-border: #D8D8D8;
$color-border-gray: #D8D8D8;


// linear gradient
$color-feature-btn-off-linear-gradient: linear-gradient(180deg, #FCFCFC, #E8EAEF, #D9DBDE);
$color-feature-btn-on-linear-gradient: linear-gradient(180deg, #A1A6B2, #EFF1F4);

// shadow
$shadow-light: 0 2px 6px #0000001F;

// 社群
$color-line-brand: #06C755; 

/* ===============================
    Fonts
=============================== */
// family
$ff-open-sans: 'Open Sans', sans-serif;
$ff-nunito: 'Nunito', sans-serif;
$ff-noto: 'Noto Sans TC', sans-serif;
$ff-roboto: 'Roboto', sans-serif;
$ff-poppins: 'Poppins', sans-serif;
$ff-msjh: "微軟正黑體", "Microsoft JhengHei", sans-serif;

$ff-normolize: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
$ff-note: 'Noto Sans TC', '微軟正黑體', '蘋果儷中黑', #{$ff-normolize};

// size
$fz-h2: 2rem; // 32px
$fz-h3: 1.5rem; // 24px
$fz-h4: 1.0625rem; // 17px
$fz-h5: 0.9375rem; // 15px
$fz-h6: 0.875rem; // 14px
$fz-mini: 0.75rem; // 12px
