
@import "~@/css/variables";
@import "~@/css/mixins";

.page-content {
    &#popup-ad {
        width: 100%;
        padding: 0;

        .popup-close {
            @include size(44px);
            border-radius: 50%;
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            color: #fff;
        }

        .ad-content {
            max-height: 100%;
        }
    }
}
