
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.muki-num-badge {
    --size: 18px;

    min-width: var(--size);
    height: var(--size);
    padding: 0;
    border-radius: var(--size);
    font-family: $ff-nunito;
    font-size: 12px;
    font-weight: 100;
    position: absolute;
    top: -10px;
    right: -10px;
    box-sizing: border-box;
    background-color: $color-danger;

    &.is-over-99 {
        // font-size: 12px;
        // width: auto;
        // right: -0.8125rem;
        // padding: 0 1px;
        // border-radius: var(--f7-badge-size);
    }

    &.sty-bold{
        font-weight: bold;
        span{
            font-weight: bold;
        }
    }
}

