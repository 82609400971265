
@import "@design";

.title-line-diivder{
    color: #ADADAD;
    font-size: 1rem;
    @include letter-spacing-center(0.05em);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .divider-title{
        flex-shrink: 0;
        margin: 0 11px;
    }
    .base-divider{
        flex-grow: 1;
    }
}

