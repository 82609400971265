
@import "@design";

.popup.sty-modal{
    --popup-width: 340px;
    background: rgba(0, 0, 0, .3);

    @include media-up(375){
        --popup-width: #{rwd-val(375px, 340px, 768px, 555px)};
    }
    @include media-up(768){
        --popup-width: 555px;
    }
    
    @mixin popup-sty-modal-default{
        .page{
            background: none;
        }
        .navbar{
            background: none!important;
            box-shadow: none;
        }
        .popup-close{
            i, a{
                color:#fff;
            }
        }
        .page-inner{
            box-sizing: border-box;
        }
        .page-content{
            transition: .3s;
            margin-left: auto;
            margin-right: auto;
            padding-top: 25px;
            padding-bottom: 25px;
            // 平衡垂直對齊
            // padding-bottom:var(--f7-page-navbar-offset);
            // 平衡垂直對齊 /
            &.popup-modal-centered{
                @include flex(flex-start,center);
            }
            > div{
                margin:auto;
                transform: translateY(- calc(var(--f7-navbar-height) + var(--f7-safe-area-top)));
            }
            .popup-modal-content{
                position: relative;
                // border-radius: $border-radius;
                &:not(.no-bg){
                    background: #fff;
                }
                width: var(--popup-width);
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;
                border-radius: 8px;
            }
        }
        .page-inner{
            max-width: 100%;
        }
    }

    @include popup-sty-modal-default;

    &.sty-light{
        .popup-modal-content{
            border-radius: 16px;
        }
        .popup-dialog-head{
            padding: 15px;
            .title{
                font-weight: 500;
            }
        }
        .popup-dialog-body{
            padding: 25px;
            padding-top: 0;
            font-size: calc(18 / 14 * 1rem);
            letter-spacing: calc(0.9 / 18 * 1em);
            line-height: calc( 24 / 18);
        }
    }

    .popup-dialog-head{
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 8px 13px;

        &:not(.sty-no-border){
            border-bottom: 1px solid $color-border;
        }

        .left{
            width: 30px;
        }
        .title{
            flex-grow: 1;
            font-size: calc(20 / 14 * 1rem);
            font-weight: bold;
            @include letter-spacing-center(calc(5 / 20 * 1em));
            color: var(--color-theme);
            text-align: center;
            padding-bottom: 0.1em;
        }
        .right{
            width: 30px;
            .popup-close{
                width: 30px;
                img{
                    width: 100%;
                }
            }
        }   
    }

    .dialog-popup-inner{
        padding: 20px;
    }

    .dialog-popup-title{
        font-size: calc(20 / 14 * 1rem);
        letter-spacing: 0.1em;
        color: var(--color-theme);
        margin-bottom: 1em;
        text-align: center;
        font-weight: bold;
    }

    .dialog-popup-desc{
        font-size: calc(18 / 14 * 1rem);
        color: #666666;
        line-height: calc(30 / 18);
        @include letter-spacing-center(0.05em);
        margin-bottom: 1rem;
    }

    .dialog-popup-btn{
        --f7-button-height: 42px;
        font-size: calc(20 / 14 * 1rem);
        @include letter-spacing-center(calc(5 / 20 * 1em));
        font-weight: bold;
    }

    @include mobile-down{
        .popup-dialog-head{
            .title{
                @include letter-spacing-center(calc(3 / 20 * 1em));
            }
        }
    }
}


