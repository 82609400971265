
@import "~@/css/variables";
@import "~@/css/mixins";

.base-radio,
.base-checkbox {
    --custom-control-active-color: #{$color-primary};
    --custom-control-inactive-color: #EEF5CC;

    display: flex;
    align-items: center;

    &.sty-light{
        --custom-control-active-color: #{$color-sec};
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 18px;
            height: 18px;
            border-width: 1px;
            border-color: $color-sec;
        }
    }

    &.sty-inactive-gray{
        --custom-control-inactive-color: #C5C5C5;
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 18px;
            height: 18px;
            border-width: 1px;
        }
    }

    &.sty-radio-check-icon{
        .icon-radio-wrap {
            i {
                width: auto;
                height: auto;
                background: none;
            }
        }
    }

    &.sty-see-through{
        .icon-checkbox-wrap{
            background: none;
        }
    }

    &.size-24px{
        .icon-checkbox-wrap, .icon-radio-wrap{
            width: 20px;
            height: 20px;
        }
    }

    &.custom-control-inline{
        display: inline-flex;
    }

    &:not(:last-child) {
        margin-right: 20px;
    }

    input {
        display: none;

        &:checked {
            ~ .icon-radio-wrap, ~ .icon-checkbox-wrap {
                border-color: var(--custom-control-active-color);
                background-color: var(--custom-control-active-color);
                .icon {
                    opacity: 1;
                    color:#fff;
                }

                ~ .radio-text {
                    color: $color-title;
                }
            }

            ~ .checkbox-text{
                color:var(--custom-control-active-color);
            }
        }
    }

    .icon-radio-wrap,
    .icon-checkbox-wrap {
        display: inline-block;
        flex-shrink: 0;
        @include size(16px);
        margin-right: 10px;
        border: 2px solid var(--custom-control-inactive-color);
        border-radius: 50px;
        position: relative;
        transition: all 0.3s ease;
        background: #FFFFFF;
    }

    .icon-radio-wrap {
        border-radius: 50%;
        i {
            width: 8px;
            height: 8px;
            background: #fff;
            border-radius: 50%;
        }
    }

    .icon-checkbox-wrap {
        border-radius: 5px;
    }

    .icon {
        @include pos-center;
        font-size: 15px;
        font-weight: bold;
        opacity: 0;
        transition: all 0.3s ease;
    }

    .radio-text,
    .checkbox-text {
        color: $color-font;
    }
}
