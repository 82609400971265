
@import "@design";

// html, body{
//     max-height: 667px; height: 667px;
// }

.ios, .md{
    .popup-cart{
        &.popup-sty-modal{
            --popup-width: 345px;
        }
    }
}

.cart-info-card{
    background: #fff;
    border-radius: 15px;
    border: 2px solid $color-primary;
    margin-bottom: 20px;
    overflow: hidden;

    &.sty-2{
        border-color: $color-sec;
        .card-main{
            background: $color-sec;
        }
    }

    .card-main{
        @include flex(center,center);
        background: $color-primary;
        padding: 20px;
        .card-title{
            font-size: 22px;
            letter-spacing: 0.1em;
            line-height: 1.3636;
            color:#fff;
            margin-right: 1.3636em;
            font-weight: bold;
        }
    }
    .card-foot{
        padding: 20px;
        text-align: center;
        .button{
            font-weight: bold;
        }
    }
}

.popup-showcase{
    .showcase-box{
        margin-bottom: 20px;
    }
}

