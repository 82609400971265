
@import "~@/css/design";

.route-visual-line{
    --route-visual-line-border: 4; // px
    --route-visual-line-gap: 2; // px
    --route-visual-line-border-portion: calc( (var(--route-visual-line-border) / var(--route-visual-line-unit-length)) * 100%);
    --route-visual-line-gap-portion: calc( (var(--route-visual-line-gap) / var(--route-visual-line-unit-length)) * 100%);
    --route-visual-line-unit-length: calc((var(--route-visual-line-border) + var(--route-visual-line-gap)));


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    img {
        flex-shrink: 0;
        &:first-child{
            height: 1rem;
            margin-bottom: 3px;
        }
        &:last-child{
            height: calc( 15.6 / 14 * 1rem);
            margin-top: 3px;
        }
    }
    .dash-line{
        width: 3px;
        flex-grow: 1;
        background: linear-gradient(to bottom, #ADADAD 0 var(--route-visual-line-border-portion), #fff 66.6668% );
        background-size: 100% calc(var(--route-visual-line-unit-length) * 1px);
        background-repeat: repeat-y;
    }
}

