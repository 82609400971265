
@import "@design";

.base-list{
    --base-list-v-gap: 15px;
    .list-item{
        margin-bottom: 15px;
    }
}

