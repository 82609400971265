
@import "@design";

.star-rating2{
    --star-font-size: 14px;
    --star-active-color: #FFC107;
    --star-inactive-color: #eceff1;

    display: inline-flex;
    align-items: center;
    font-size: var(--star-font-size);
    justify-content: center;
    position:relative;

    &.star-rating-readonly, .is-readonly{
        cursor: default;
        .back-stars{
            cursor: default;
        }
    }

    &.no-rating-count{
        .rating-count{
            display: none;
        }
    }

    .back-stars {
        display: flex;
        color:var(--star-inactive-color);
        position: relative;
        cursor:pointer;
        margin-left: -2.5px;
    }
    .back-stars,.fonrt-stars{
        i{
            margin:0 7px;
            flex-shrink: 0;
        }
        img{
            width: var(--star-font-size);
            min-width: var(--star-font-size);
        }
    }
    .front-stars {
        display: flex;
        color: var(--star-active-color);
        overflow: hidden;
        position: absolute;
        top: 0;
    }

    .fa{
        position:relative;
        .half-left,.half-right{
            position:absolute;
            display:block;
            top:0;
            bottom:0;
            width:50%;
        }
        .half-left{
            left:0;
        }
        .half-right{
            right:0;
        }
    }

    .rating-count {
        color:#929295;
        font-size: 12px;
        margin-bottom: -2px;
        margin-left: 3px;
    }

    .rating-txt{
        color:$color-font;
        font-size: 12px;
        margin-bottom: -2px;
        margin-left: 3px;
    }

    .ani-star{
        top: 0;
        opacity: 0;
        transform: rotateY(0deg);
        &.is-ani{
            animation: star-rating-ani .6s 1;
            animation-fill-mode: forwards;
            @include keyframes(star-rating-ani) {
                0% {
                    top: 0;
                    opacity: 0;
                    transform: rotateY(0deg);
                }
                33.33%{
                    top: -20px;
                    opacity: 1;
                    transform: rotateY(0deg);
                }
                33.34% {
                    top: -20px;
                    transform: rotateY(0deg);
                    opacity: 0.7;
                }
                66.66% {
                    top: -20px;
                    transform: rotateY(180deg);
                    opacity: 0.7;
                }
                66.67% {
                    top: -20px;
                    opacity: 0.7;
                    transform: rotateY(180deg);
                }
                100%{
                    top: -20px;
                    opacity: 0;
                    transform: rotateY(180deg);
                }
            }
        }
    }
}

