/*-------------------------------------------------------------------
  文字取代
-------------------------------------------------------------------*/
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}


/*-------------------------------------------------------------------
  轉數值
-------------------------------------------------------------------*/
@function to-number($value) {
  @if type-of($value) == 'number' {
    @return $value;
  } @else if type-of($value) != 'string' {
    @error 'Value for `to-number` should be a number or a string.';
  }

  $result: 0;
  $digits: 0;
  $minus: str-slice($value, 1, 1) == '-';
  $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);

  @for $i from if($minus, 2, 1) through str-length($value) {
    $character: str-slice($value, $i, $i);

    @if (index(map-keys($numbers), $character) or $character == '.') {
      @if $character == '.' {
        $digits: 1;
      } @else if $digits == 0 {
        $result: $result * 10 + map-get($numbers, $character);
      } @else {
        $digits: $digits * 10;
        $result: $result + map-get($numbers, $character) / $digits;
      }
    }
  }

  @return if($minus, -$result, $result);;
}


/*-------------------------------------------------------------------
  rwd
-------------------------------------------------------------------*/
// Linear interpolations in CSS as a Sass function
// Author: Mike Riethmuller | https://madebymike.com.au/writing/precise-control-responsive-typography/ I
@function rwd-val(
  $min-screen,
  $min-value,
  $max-screen,
  $max-value
) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: '+';

  @if ($b < 0) {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$a} * var(--view-width) #{$sign} #{$b});
}

/*-------------------------------------------------------------------
  map-get-or
-------------------------------------------------------------------*/
@function map-get-or($map, $key, $orValue){
  $result: map-get($map, $key);

  @if $result == null {
    @return $orValue;
  };

  @return $result;
}