
@import "@design";

.muki-num-badge {
    min-width: auto;
    min-width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 18px;
    font-family: $ff-nunito;
    font-size: 12px;
    font-weight: 100;
    position: absolute;
    top: -10px;
    right: -10px;
    box-sizing: border-box;
    background-color: $color-danger;
    color: #fff;
    &.is-over-99 {
        // font-size: 12px;
        // width: auto;
        // right: -0.8125rem;
        // padding: 0 1px;
        // border-radius: var(--f7-badge-size);
    }

    &.sty-bold{
        font-weight: bold;
        span{
            font-weight: bold;
        }
    }
}

