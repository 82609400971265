
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.content-scroller{
    display: flex;
    flex-direction: column;

    &.has-tab{
        .tab{
            overflow-y: auto;
        }
    }

    &.sty-fill-height{
        flex-grow: 1;
        min-height: 100%;
    }

    &.sty-body-scroll{
        .scroller-body{
            overflow-y: auto
        }
    }

    .scroller-body{
        flex-grow: 1;
        min-height: 0;
    }
    .scroller-static{
        flex-shrink: 0;
    }
};

