
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.base-grape{
    --base-grape-gap: 10px;
    --grape-inner-display: flex;
    margin-bottom: 12px;

    &.sty-h-center{
        .grape-inner{
            justify-content: center;
        }
    }

    .grape-inner{
        margin-bottom: calc(var(--base-grape-gap) * -1);
        display: var(--grape-inner-display);
        flex-wrap: wrap;
    }
    
    .grape-item{
        margin-bottom: var(--base-grape-gap);
        margin-right: var(--base-grape-gap);
    }

    &.sty-single-line-align-right{
        .grape-item{
            &:last-child{
                margin-right: 0;
            }
        }
    }

}

