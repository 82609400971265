
@import "~@/css/design";

.base-divider{
    --divider-color: #ADADAD;

    height: 1px;
    background: #EAECED;
    user-select: none;
    &.sty-dotted{
        background: linear-gradient(to right, var(--divider-color) 0 50%, #fff 50.01% );
        background-size: 4px 100%;
        background-repeat: repeat-x;
    }

    &.sty-bold{
        height: 10px;
        background: #D8D8D8;
    }

    &.sty-medium{
        height: 2px;
    }
}

