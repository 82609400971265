
@import "@design";

.popup.sty-modal.sty-dialog2{
    --popup-width: 340px;

    @include media-up(375){
        --popup-width: #{rwd-val(375px, 340px, 768px, 555px)};
    }
    @include media-up(768){
        --popup-width: 555px;
    }

    .dialog-popup-inner{
        padding: 20px;
    }

    .dialog-popup-title{
        font-size: calc(20 / 14 * 1rem);
        letter-spacing: 0.1em;
        color: var(--color-theme);
        margin-bottom: 1em;
        text-align: center;
        font-weight: bold;
    }

    .dialog-popup-desc{
        font-size: calc(18 / 14 * 1rem);
        color: #666666;
        line-height: calc(30 / 18);
        @include letter-spacing-center(0.05em);
        margin-bottom: 1rem;
    }

    .dialog-popup-btn{
        --f7-button-height: 42px;
        font-size: calc(20 / 14 * 1rem);
        @include letter-spacing-center(calc(5 / 20 * 1em));
        font-weight: bold;
        font-family: $ff-msjh;
    }
}


