
@import "@design";

.border-custom-control-hori-box{
    display: inline-flex;
    align-items: center;
    .box-title{
        font-size: 1rem;
        letter-spacing: calc(2.8 / 14 * 1em);
        margin-right: 10px
    }
}

.border-custom-control{
    --custom-control-size: 18px;
    --custom-control-active-color: var(--color-theme);
    --custom-control-inactive-color: #ADADAD;
    
    box-sizing: border-box;
    border-radius: 50%;

    *{
        box-sizing: inherit;
    }

    input {
        display: none;
    }

    .custom-control-inner{
        display: flex;
        align-items: center;
    }

    // 預設 icon
    .custom-control-icon-default{
        width: var(--custom-control-size);
        height: var(--custom-control-size);
        flex-shrink: 0;
        border: 2px solid var(--custom-control-inactive-color);
        position: relative;
        border-radius: 50%;
        background: #fff;
        margin-right: 9px;
        .icon-inner{
            @include absolute(2px, 2px, 2px, 2px);
            background: var(--custom-control-inactive-color);
            border-radius: inherit;
            opacity: 0;
        }
    }
    // 預設 icon
    

    &.is-checked{
        .custom-control-icon-default{
            border-color: var(--custom-control-active-color);
        }
        .icon-inner{
            opacity: 1;
            background: var(--custom-control-active-color);
        }
    }
}

