
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.nav-status-badge{
    
    &.sty-ongoing{
        .badge{
            background: $color-primary;
        }
    }

    &.sty-complete{
        .badge{
            background: $color-yellow;
        }
    }

    .badge{
        border-radius: 22px;
        font-size: 1rem;
        letter-spacing: calc( 2.8 / 14 * 1em);
        padding: 3px;
        min-width: 90px;
        font-weight: 500;
        height: auto;
        line-height: 1.43;
        box-shadow: $shadow-light;
        border: 1px solid #FFFFFF;
    }
}

