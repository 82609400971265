
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.base-nav-back{
    &[disabled="true"]{
        opacity: 0.7;
        pointer-events: none;
    }
}

