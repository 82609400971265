@import "~@/css/variables";
@import "~@/css/function";
@import "~@/css/mixins";
@import "~@/css/grid";
@import "~@/css/utils";
@import 'icons.css';
@import 'icomoon/style.css';

/* Custom color theme properties */
.ios, .md{
    // ==== f7變數 ====
    // 修改預設值
    --f7-font-family: #{$ff-note};
    --f7-text-color: #{$color-font};
    // safe area
    --f7-safe-area-top: constant(safe-area-inset-top); /* iOS Safari 11.2, Safari 11 */
    --f7-safe-area-top: env(safe-area-inset-top); /* iOS Safari 11.4+, Safari 11.1+, Chrome 69+, Opera 56+ */
    // safe area /
    --f7-page-bg-color: #fff;
    // --navbar-extra-safe-top: 0px;
    --navbar-extra-safe-top: calc(var(--f7-safe-area-top) * 0.5);
    --navbar-height: 64px;
    --f7-navbar-height: calc(var(--navbar-height)  /* 避免navbar logo 剛好切齊上方，故多加一些空間*/);
    --f7-toolbar-height: 64px;
    --f7-input-font-size: 16px;
    --f7-input-placeholder-color: #515357;
    --f7-button-text-transform: none;
    --f7-button-font-weight: normal;
    --f7-sheet-bg-color:#F0F2F7;
    --f7-fab-size:54px;
    --f7-navbar-link-color: #{$color-primary};
    --f7-panel-backdrop-bg-color: rgba(0,0,0, 0.6);
    --f7-toggle-active-color: #{rgba($color-primary, 0.8)};
    --f7-toggle-inactive-color: #{rgba(#ADADAD, 0.8)};
    --f7-toggle-border-color: #{rgba(#ADADAD, 0.8)};
    --f7-link-highlight-color: #{rgba(#FD8908, 0.2)};
    // button
    --f7-dialog-button-text-color: #FD8908;
    --f7-toast-bg-color-rgb: 253,137,8;
    // button /
    // dialog
    --f7-dialog-title-font-size: 20px;
    --f7-dialog-button-font-size: 20px;
    // dialog /
    // toast
    --f7-toast-bg-color: #FD8908;
    // toast /
    // toggle
    --f7-toggle-width: 55px;
    --f7-toggle-height: 32px;
    // toggle /
    // 修改預設值 /
    --navbar-bg: transparent;
    // ==== 自訂變數 ====
    --color-theme: #{$color-primary};
    --color-primary: #{$color-primary};
    --color-sec: #{$color-sec};
    --ff-open-sans: 'Open Sans', sans-serif;
    --ff-nunito: 'Nunito', sans-serif;
    --ff-noto: 'Noto Sans TC', sans-serif;
    --ff-roboto: 'Roboto', sans-serif;
    --ff-poppins: 'Poppins', sans-serif;
    --ff-msjh: "微軟正黑體", "Microsoft JhengHei", sans-serif;
    --ff-note: 'Noto Sans TC', '微軟正黑體', '蘋果儷中黑', Arial, Helvetica, sans-serif;

    @include mobile-down{
        --f7-input-font-size: 14px;
    }
}
/* Your app custom styles here */

// ==== 基礎 ====

html{
    *:not(input):not(select):not(textarea){
        user-select: none;
        -webkit-user-select: none;
    }
}


.ios,
.md {
    body {
        // max-width: 768px;
        margin: 0 auto;
    }

    // common
    img {
        max-width: 100%;
        max-height: 100%;
    }

    .date {
        display: flex;
        align-items: center;
        font-size: $fz-mini;
        color: $color-tip;

        img {
            margin-right: 0.4375rem;
        }
    }
}
// ==== 基礎 / ====

//==== 模組 ====

// 圖片裁切
.popup-crop_picture{

    // 大頭照
    &.sty-headshot{
        #crop_picture_preview {
            border-radius: 50%;

            .cr-boundary{
                // 50% border radius
                border-radius: 50%;
            }
        }

        button#crop_picture_select_btn{
            right: 50%;
            transform: translateX(175px) translateY(0);

            @media screen and (max-width: 699px) {
                right: 13%;
                transform: translateX(0);
            }
        }
    }

    .page-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .crop_picture_block{
        margin-top: 15px;
        margin-bottom: 0;
    }

    .explain-wrap{
        text-align: center;
        margin-bottom: 35px;
    }

}
#crop_picture_preview {
    touch-action: pan-y;
    width: 80%;
    background: -webkit-gradient(linear, left top, left bottom, from(#333333), color-stop(100%, #666666), to(#666666));
    background: linear-gradient(#333333 0%, #666666 100%, #666666 100%);
    margin-left: 5px;
    margin-right: 5px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 0 13px #9DB2DB;
    max-width: 350px;
    // 裁切 overflow hidden失效處理
    position: relative;
    .cr-boundary{
        overflow: hidden;
        z-index: 0;
    }
    .cr-viewport{
        // 避免套件預設 會導致 白邊
        border:0;
    }// 裁切 overflow hidden失效處理 (end)

}

#crop_picture_select_group{
    margin:0;
    padding: 13px 0;
}

button#crop_picture_select_btn {
    background: #9DB2DB;
    border: 0;
    font-size: 30px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
    z-index: 10;
    @include flex(center,center);
}
button#crop_picture_rotate_btn {
  i {
    margin-right: 5px;
    margin-top: -1px;
  }
}
.crop_picture_title {
    font-size: 20px;
    font-weight: bold;
    color: #9DB2DB;
    margin: 40px 0 10px;
}

.crop_picture_tip {
    color: $color-font;
    font-size: 16px;
}

#crop_picture_upload_btn {
    border: 0;
    margin: 0 auto;
    line-height: 44px;
    height: 44px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 5px;
}


.crop_picture-btn-wrap {
    button, #crop_picture_upload_btn{
        width: 100%;
    }

    button i.btn-icon{
        font-size: 1.08em;
    }
}

.crop_picture_wrap {
    position: relative;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}


// embed-responsive
.embed-responsive{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &:before{
        display: block;
        content: "";
    }
    &.embed-responsive-16by9{
        &:before{
            padding-top: 56.25%;
        }
    }
    &.embed-responsive-345by130{
        &:before{
            padding-top: calc(130 / 345 * 100%);
        }
    }

    iframe, img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

// f7 內建 覆寫

// swiper
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
    // disable後仍然要可以點的到
    pointer-events: all;
}
// swiper /

// toggle
.ios, .md{
    .toggle{
        @include f7-toggle-ios-md-same;
    }
}
// toggle /

// toast
.ios, .md{
    &.is-softkeyboard-open{
        .toast.toast-top{
            top: var(--softkeyboard-height);
        }
    }
}

// toast /
// ==== 模組 ==== /


// ==== 內建擴充 ====
.ios, .md{

    body.is-scrolling{
        .navbar{
            // box-shadow: 0 3px 10px rgba(#000, 0.16);
        }
        .navbar .navbar-bg{
            opacity:1;
        }
        .popup{
            // .navbar{
            //     background: #fbfbfb;
            // }
        }
    }

    body:not(.is-scrolling){
        .page.no-page-top-shadow-if-no-scroll{
            &:before{
                opacity: 0;
            }
        }
    }

    .disabled{
        opacity: 0.35!important;
    }

    // Navbar
    body:not(.is-scrolling){
        // nav-no-bg-when-no-scroll
        .navbar.navbar-no-bg-when-no-scroll{
            .navbar-bg{
                opacity: 0;
            }
        }
    }
    body.is-scrolling{
        .navbar.navbar-bg-primary-on-scroll{
            .navbar-bg{
                --navbar-bg: #{$color-primary};
            }
        }
    }
    .navbars{
    }
    .navbar {
        // f7 內建參數只有 pass 到 ~ *。
        // 在此將餐數也pass到子元件
        --f7-page-navbar-offset: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
        --f7-page-subnavbar-offset: var(--f7-subnavbar-height);


        &.nav-color-w{
            --f7-navbar-link-color: #fff;
            color:#fff;
        }

        &.bg-transparent {
            background: transparent;

            .navbar-bg {
                background: transparent;
            }
        }

        &.navbar-pd-24px{
            .navbar-inner{
                padding: 0 24px;
            }
        }

        &.navbar-sm-pd{
            .navbar-inner{
                padding: 0 10px;
            }
        }

        &.navbar-no-bg{
            .navbar-bg{
                opacity: 0;
            }
        }

        &.navbar-no-bg{
            .navbar-bg{
                opacity: 0;
            }
        }

        &.navbar-no-bg-always{
            box-shadow: none;
            .navbar-bg{
                display:none;
            }
        }

        &.navbar-bg-primary{
            background: $color-primary;
        }

        &.navbar-inset-20px{
            .navbar-inner{
                padding: 0 20px;
            }
        }

        &.navbar-default-layout{
            &:before{
                content:'';
                display: block;
                background: #FDF0EA;
                border-radius: 0 0 24px 24px;
                box-shadow: 0 3px 12px #00000026;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                height: calc( 82 / 92 * 100%);
            }
            .navbar-inner{
                align-items: stretch;
            }
            .base-nav-logo{
                position: absolute;
                top: auto;
                right: 0;
                bottom: auto;
                transform: translateY(4px);
            }
            .navbar-bg{
                opacity: 0;
                display:none;
            }
            .title{
                font-size: calc(20 / 14 * 1rem);
                font-weight: bold;
                letter-spacing: calc( 5 / 20 * 1em);
                color: #666666;
            }
        }

        &.navbar-enlarge-layout{
            .base-nav-back{
                img{
                    width: 49px;
                }
            }
            .base-nav-logo{
                background: none;
                box-shadow: none;
            }
            .title{
                font-size: calc(20 / 14 * 1rem);
                letter-spacing: calc( 5 / 20 * 1em);
                color: #666666;
                font-weight: bold;
            }

            &.navbar-enlarge-layout_long-title{
                .title{
                    br{
                        display: none;
                    }
                }
                @include media(374.98){
                    .title{
                        br{
                            display: block;
                        }
                    }
                }
                // @include media(375){
                //     .title{
                //         font-size: rwd-val(320px, 15px, 375px, 20px);
                //     }
                // }
                // @include media(320){
                //     .title{
                //         font-size: 15px;
                //     }
                // }
            }

            @include media(375){
                .base-nav-back{
                    img{
                        width: rwd-val(320px, 39px, 375px, 49px);
                    }
                }
            }
            @include mobile-xs{
                .base-nav-back{
                    img{
                        width: 39px;
                    }
                }
            }
        }

        &.navbar-bg-round-board{
            &:before{
                content:'';
                display: block;
                position: absolute;
                background: #FDF0EA;
                left: 0;
                top: 0;
                right: 0;
                // height 使用 f7 內建值
                height: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
                box-shadow: 0 3px 8px rgba(#000, 0.12);
                border-radius: 0 0 16px 16px;
                z-index: 0;
            }
            .navbar-bg{
                opacity: 0;
            }
        }

        &.sty-has-muki-tabbar{
            --f7-navbar-height: 50px;
            --f7-subnavbar-height: 54px;
        }

        .navbar-inner {
            padding: 0 15px;
            padding-top: var(--navbar-extra-safe-top);
            .right{
                justify-content: flex-end;
            }
        }

        .navbar-bg {
            --navbar-bg: rgba(255,255,255,0.1);
            background: var(--navbar-bg);
            backdrop-filter: saturate(180%) blur(var(--f7-bars-translucent-blur));
        }

        .left {
            .icon-chevron-left {
                font-size: 1.875rem;
                font-weight: 600;
                background: -webkit-linear-gradient(180deg, #9db2db, #1b70aa);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .title {
            font-family: $ff-noto;
            font-size: 1.2857rem;
            letter-spacing: 0.1em;
            @include flex(center,center);
            img {
                height: calc( 30 / 14 * 1rem);
            }
        }

        .right {
            position:relative;
            img {
                height: 1.875rem;
            }
        }

        .link {
            height: auto;
            padding: 0 3px;
        }

        .f7-nav-title-txt{
            display: inline-block;
        }

        .nav-back-btn{
            width: 36px;
            height: 36px;
            background: rgba(#000000, 0.2);
            color:#fff;
            border-radius: 50%;
            @include flex(center,center);
            font-size: 26px;
        }

        .nav-back-angle{
            width: 23px;
        }

       
        // icon
        i {
            font-size: 20px;
        }
        .icon-mail{
            font-size: 17.6px;
        }
        .icon-menu_hamgurg{
            font-size: 16px;
        }
        .fa-angle-left{
            font-size: 26px;
        }
        .icon-trash{
            font-size: 20px;
        }
        .icon-text-file{
            font-size: 20px;
        }
        // icon /

        // 其他
        .navbar-select-item{
            display: flex;
            max-width: 71%;
            min-width: 120px;
            color: $color-font;
            font-size: 18px;
            letter-spacing: 0.1em;
            font-weight: bold;
            .item-content{
                min-width: 0;
                @include text-ellipsis();
            }
            .item-append{
                flex-shrink: 0;
            }
        }

        .icon-cart1{
            .muki-num-badge{
                top: -7px;
                right: -7px;
            }
        }
        // 其他
    }

    // navbar
    .navbar-inner {
        justify-content: space-between;

        .left,
        .right {
            min-width: 2.75rem;

            .link {
                min-width: 28px;
                @include flex(center,center);
                padding: 0;
            }
        }

        .right {
            margin-left: 0.625rem;
        }
    }
    // navbar /

    // subnavbar
    .subnavbar{
        &.clear-f7{
            background: none;
            backdrop-filter: none;
            &:before{
                display: none;
            }
            &:after{
                display: none;
            }
        }
    }
    // subnavbar /

    // Page
    .page {
        background-image: var(--base-bg);
        // background-size: cover;
        // background-position: center center;

        &.no-toolbar{
            &:not(.has-fixed-bottom-toolbar){
                --f7-page-toolbar-bottom-offset: 0;
            }
        }
        &:not(.no-toolbar){
            // 有toolbar-menu;
            --f7-toolbar-height: 70px;
        }
    }
    // Page /

    // sheet-modal
    .sheet-modal .toolbar{
        background: #EEF5CC;
        backdrop-filter: none;
        border-radius: 10px 10px 0 0;
    }
    .sheet-modal{
        --f7-picker-column-font-size: 24px;
        background: #fff;
        box-shadow: 0 -5px 10px #00000029;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        &:before{
            display: none;
        }
        .link{
            color:$color-primary;
            font-weight: bold;
            font-size: 18px;
        }
        .sheet-modal-inner{
            -webkit-mask-box-image: none;
            background: none;
        }
        .picker-columns{
          // 1os 16.4.1 使用-webkit-mask-box-image會出現內容被遮擋問題
          -webkit-mask-box-image: none;
        }
        .picker-column{
            z-index: 2;
        }
        .picker-item{
            span{
            }
            &:not(.picker-item-selected){
                span{
                    transform: scale(calc( 22 / 24));
                    display: inline-block;
                    backface-visibility: hidden;
                }
            }
        }
        .picker-item-selected{
            color:#fff;
            // font-size: 22px;
            span{
                // transform: scale(calc( 22 / 18));
                transform-origin: center;
                display: inline-block;
            }
        }
        .picker-center-highlight{
            z-index: 1;
            background: $color-primary;
            border-radius: 18px;
        }
    }
    .sheet-backdrop{
        backdrop-filter: blur(15px);
        background: rgba(#000, 0.61);
    }
    .custom-sheet-modal{
        .sheet-modal-inner{
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .custom-sheet-modal-toolbar{
        height: 54px;
        position: relative;
        margin-bottom: 10px;
        @include project-border-bottom(#D8D8D8);
        .custom-sheet-modal-title{
            color: var(--color-theme);
            font-size: calc( 20 / 14 * 1rem);
            letter-spacing: calc(5 / 20 * 1em);
            text-align: center;
        }
        .row{
            height:100%;
            align-items: center;
        }
        .right{
            text-align: right;
        }
    }
    // sheet-modal /

    // calendar
    .calendar-day{
        --f7-calendar-day-font-size: 14px;
        flex-wrap: nowrap;
        white-space: nowrap;
    }
    .calendar-day-selected .calendar-day-number{
        background: $color-primary;
    }
    .calendar-day-selected-range .calendar-day-number{
        background: transparent;
    }
    .calendar-day-selected-range:before, .calendar-day-selected-left:before, .calendar-day-selected-right:before{
        background:rgba($color-sec, .7);
    }
    // calendar /

    // panel
    .panel{

        &.panel-left{
            --f7-panel-left-width: 315px;
        }

        .page-content{
            margin-left: 10px;
        }

        .panel-close{
            font-size: 18px;
            color: $color-primary;
        }
        .page-inner{
            padding: 0 25px;
        }

        ul{
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                margin-bottom: 30px;
                display: flex;
            }
        }

        .panel-menu-link{
            color: #231815;
            font-size: 18px;
            font-family: $ff-msjh;
        }

        .panel-menu-link-icon-wrap{
            width: 31px;
            @include flex(center,center);
            margin-right: 30px;
        }

        .panel-menu-link-text{
            font-weight: bold;
        }

        .panel-top-block{
            margin-top: 24px;
            margin-bottom: 42px;
            padding: 0;
        }

        // 其他
        .mail-list{
            .panel-menu-link-text{
                display: flex;
                align-items: center;
            }
            .muki-num-badge{
                position: relative;
                top: auto;
                right: auto;
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
        }
        // 其他 /

        @include mobile-down{
            &.panel-left{
                --f7-panel-left-width: 265px;
            }

            .panel-top-block{
                margin-bottom: 26px;
            }

            ul {
                li {
                    margin-bottom: 24px;
                }
            }
        }
    }
    // panel /

    // toolbar-menu
    .toolbar-menu{
        background: #fcfcfc;
        background-repeat: repeat-x;
        font-size: 0.857rem;
        border: 1px solid #F8F8F8;
        border-radius: 22px 22px 0 0 ;
        box-shadow: 0 -3px 16px #00000026;
        backdrop-filter: blur(30px) brightness(1.5) opacity(0.9);

        &:after, &:before{
            display: none;
        }

        .col-item{
            flex-grow: 1;
            // is-active 不放在 menu-link
            // 為了避免跟 f7 控制active-state 的 產生可能的衝突
            // 導致 active-state 會留在html上
            &.is-active{
                .menu-link{
                    background: $color-primary;
                    color:#fff;
                    svg{
                        circle, rect, path{
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .menu-link{
            height:auto;
            color: #939393;
            line-height: 1.4;
            border-radius: 22px;
            padding: 8.2px 10px;
            width: 60px;
            height: 60px;
            margin:auto;
            border-radius: 50%;
            border: 2px solid #fff; 
            background: #eaeced;
            flex-direction: column;
            box-shadow: 0 2px 6px rgba(#000, 0.16);
            .muki-num-badge{
                top: -8px;
                right: auto;
                left: 100%;
                min-width: 22px;
                height: 22px;
                font-size: 15px;
                border-radius: 22px;
                padding: 3px;
            }
            i {
                font-size: 24px;
                margin-bottom: 1px;
                line-height: 1;
            }
            .menu-icon{
                position: relative;
                width: 24px;
                height: 24px;
                line-height: 1;
                margin-bottom: 1px;
                svg{
                    width: 100%;
                    circle, rect, path{
                        fill: #939393;
                    }
                }
            }
            .txt{
                line-height: 1;
                font-size: 17px;
                letter-spacing: 0.05em;
                font-weight: 500;
                margin-bottom: 3px;
            }
        }

        .icon-cart{
            position: relative;
            .muki-num-badge{
                top: -8px;
            }
        }

        @include mobile-down{
            .menu-link{
                padding: 8.2px 8.5px;
            }
        }

        @include media-up(376){
            .menu-link{
                width: rwd-val(376px, 54px, 768px, 60px);
                height: rwd-val(376px, 54px, 768px, 60px);
            }
        }

        @include media-up(414){
            .menu-link{
                letter-spacing: 0.1em;
            }
        }
    }
    // toolbar-menu /

    // fixed-bottom-toolbar
    .fixed-bottom-toolbar{
        background-repeat: repeat-x;
        background: rgba(255, 255, 255, 0.85);
        // backdrop-filter: saturate(180%) blur(var(--f7-bars-translucent-blur)) opacity(0);
        backdrop-filter: blur(30px) opacity(0) brightness(15%);

        &:before, &:after{
            display: none;
        }

        &.transparent{
            background: none;
            backdrop-filter: none;
            -webkit-backdrop-filter: none;
        }

        &.toolbar-px-15px{
            .toolbar-inner{
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .toolbar-inner{
            > .row{
                width: 100%;
            }
        }

        &.sty-product-detailed{
            .toolbar-side{
                width: 30px;
                text-align: center;
                margin-right: 17px;
                .fav-toggle{
                    margin-bottom: 5px;
                    font-size: 20px;
                }
                .fav-text{
                    font-size: 12px;
                }
            }
            .toolbar-main{
                flex-grow: 1;
            }
        }

        &.sty-cart{
            .row{
                align-items: center;
            }
            .total-displayer{
                display: flex;
                align-items: center;
                font-size: 20px;
                .displayer-title{
                    margin-right: 6px;
                    font-size: 14px;
                }
            }
            .toolbar-btn{
                width: auto;
                min-width: 100px;
                display: inline-flex;
            }
            .col-right{
                text-align:right;
            }
        }
    }
    // fixed-bottom-toolbar /

    // list
    .list{

        ul {
            background: none;
            &:before{
                display:none;
            }
            &:after{
                display:none;
            }


        }

        li {
            &.is-unread{
                &:before{
                    background-color: $color-sec;
                    border-radius: 50%;
                    content: "";
                    height: .525rem;
                    width: .525rem;
                    left: 6px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &.v-gape-16px{
            ul {
                > li{
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
        }

        &.v-gape-18px{
            ul {
                > li{
                    padding-top: 18px;
                    padding-bottom: 18px;
                }
            }
        }

        &.top-line{
            ul {
                > li:first-child{
                    border-top: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.tail-line{
            ul {
                > li:last-child{
                    border-bottom: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.sty-lined{
            ul {
                > li {
                    border-bottom: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.sty-inset{
            ul > {
                li {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        &.sty-inset-20px{
            ul > {
                li {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        @include mobile-down{
            &.sty-inset{
                ul > {
                    li {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }

            &.sty-inset-20px{
                ul > {
                    li {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    // list /

    // product-list
    .product-list{
        ul {
            > li {
                padding-left: 17px;
                padding-top: 26px;
                padding-bottom: 18px;
                padding-right:10px;
            }
        }
    }
    // product-list /


    // media-list
    .media-list{
        .item-inner:after{
            display: none;
        }
    }
    // media-list

    // muki-tab-wrap
    .muki-tab-wrap{
        padding: 0 15px;
    }
    // muki-tab-wrap /

    // tabs
    .tab-custom-scroll{
        .tab:not(.tab-active){
            max-height: 0;
        }
    }
    // tabs /

    // tab-sty-anchor
    .tab-sty-anchor{
        --f7-toolbar-height: 50px;
        --f7-segmented-strong-between-buttons: 10px;
        background:#EEF5CC;
        margin-bottom: 15px;

        .toolbar-inner{
            border-radius: 0;
            background: none;
        }

        .tab-link{
            color: $color-primary;
            border: 1px solid $color-primary;
            border-radius: 15px;
            font-size: 1rem;
            overflow: visible;
            &.tab-link-active{
                background: $color-primary;
                color:#fff;
                position:relative;

                &:after{
                    content: '';
                    display: block;
                    width: 14px;
                    height: 13px;
                    border-radius: 3px;
                    position: absolute;
                    left: 50%;
                    top: 100%;
                    transform: translateX(-50%) translateY(-7px) rotate(45deg);
                    background: $color-primary;
                    transform-origin: center;
                }
            }

            &:first-child{
                margin-left: 15px;
                border: 1px solid $color-primary;
            }
            &:last-child{
                margin-right: 15px;
            }
        }
    }
    // tab-sty-anchor /

    // tab-sty-groove
    .tab-sty-groove{
        font-size: 0.93rem;
        box-shadow: inset 0 3px 6px #373F5629;
        background: linear-gradient(181deg, #CCCFD7, #E8EAEF, #E7E9EE, #F1F1F1);
        border-radius: 7px;
        backdrop-filter: none;
        padding: 7px 7px;
        height: 57px;
        margin-bottom: 10px;

        &.tab-fz-16px{
            font-size: Max(16px, 1rem);
        }

        .link, .tab-link{
            // reset f7 segement
            width: auto;
            // reset f7 segement /
            height: 40px;
            line-height: 40px;
            min-width: 112px;
            padding: 5px 12px;
            font-size: 1em;
            color: $color-font;
            flex-shrink: 0;
            text-transform: none;
        }

        .tab-link-active{
            color:#fff;
            font-weight: normal;
            border-radius: 6px;
        }


        .toolbar-inner{
            &:not(.segmented) {
                .tab-link-active{
                    background: linear-gradient(121deg, #71C7DF, #0498D8, #59BCCB);
                }
            }
        }


        .button{
            + .button{
                margin-left: 0;
            }
        }

        .tab-link-content{
            display: inline-block;
        }

        .segmented{
            background:none;
        }

        .segmented-highlight, .tab-link-bg-hilighter{
            position: absolute;
            border-radius: 6px;
            height: 40px;
            background: linear-gradient(121deg, #71C7DF, #0498D8, #59BCCB);
            width:auto;
            min-width: 112px;
            left: 0;
            transition: .3s;
        }


        @include media-up(768) {
            font-size: 1rem;
        }
    }
    // tab-sty-groove /

    // fab
    .fab-cart{
        a {
            font-size: 22px;
            background: #F8334D;
            box-shadow: 0 3px 6px #FF2E2E80;
        }
        i {
            margin-left: -3px;
            margin-top: 4px;
        }
        .badge{
            width: 18px;
            height: 18px;
            min-width: auto;
            font-size: 12px;
            position: absolute;
            right: 8px;
            top: 13px;
            border: 2px solid #F38A99;
            box-shadow: 0 3px 6px #00000029;
            @include flex(center,center);
        }
        .num{
            display: inline-block;
            transform: scale(calc(11 / 12));
        }
    }

    .fab-add-cart, .fab-cart-toggle{
        bottom: calc(var(--f7-fab-margin) + var(--f7-safe-area-bottom) + 61px);
        a {
            font-size: 22px;
            background: #fff;
            box-shadow: 0 3px 6px #00000052;
            color: #006fbc;
        }
        img {
            width: 64.8%;
            transform: translateX(-2%);
        }
    }
    // fab /

    // popup
    .popup{

        // popup-bg
        &.popup-bg-apple{
            backdrop-filter: saturate(90%) blur(30px) brightness(1.5);
            background: rgba(255, 255, 255, 0.1);
        }
        // popup-bg /

        .navbar{
            .popup-close{
                i {
                    font-size: 25px;
                }
            }
        }

        // popup-tail-close
        .popup-tail-close{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 15px;
        }
        // popup-tail-close/

        // popup-section-title
        .popup-section-title{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        // popup-section-title /

        // popup-form-field
        .popup-form-field{
            display: flex;
            align-items: center;
            border: 1px solid $color-blue;
            color: $color-blue;
            border-radius: 6px;
            i {
                font-size: 24px;
            }
            input, select, textarea {
                flex-grow: 1;
                padding: 6px 10px;
                font-size: 16px;
                &::placeholder{
                    color:rgba(#000000, .4);
                }
            }
            input{
                height: 34px;
            }
            .field-append{
                margin-right: 8px;
                @include flex(center,center);
            }
            &.sty-blue{

            }
        }
        // popup-form-field /
    }

    .popup.popup-bg-apple{
        &.popup .popup-modal-content{
            width: 345px;
        }
    }

    .popup.popup-codes{
        .muki-barcode{
            &:only-child{
                margin-bottom: 0;
            }
        }
    }
    // popup /

    // picker-sheet
    .picker-sheet{
        .toolbar{
            height: 40px;
        }
    }
    // picker-sheet /

    // actions group
    .actions-modal{
        padding-bottom: 15px;
        .actions-button-text{
            font-size: calc(22 / 14 * 1rem);
            letter-spacing: 0.1em;
        }
    
        .actions-group{
            margin-left: 17px;
            margin-right: 17px;
    
            .actions-button-white{
                background:#fff;
                color: #ADADAD;
            }
    
            .actions-button-theme{
                color: var(--color-theme);
                background: rgba(255,255,255, 0.8);
            }
        }

        @include media-up(376){
            .actions-button-text{
                font-size: rwd-val(376px, 23px, 768px, 27px);
            }
        }

    }
    
    .popup-backdrop.backdrop-in{
        backdrop-filter: blur(15px);
        background: rgba(#000, 0.61);
    }
    // actions group /

    // photo browser
    .photo-browser-popup {
        .navbar {
            a {
                color: $color-sec;
            }
        }
    }
    .photo-browser-page{
        .toolbar {
            a {
                color:$color-sec;
            }
        }
    }
    // photo browser /

    
}
// ==== 內建擴充  / ====

// ==== 指令 ====
.ios, .md{
    // bg
    .page-bg-2area{
        // 區塊固定在位置上
        --page-bg-2area-size: 35.37%;
        background: linear-gradient(to bottom, #FDF0EA 0 var(--page-bg-2area-size), #EFEFEF var(--page-bg-2area-size));
    }
    .page-bg-2area-flow{
        // 區塊跟著內容一棟
        --page-bg-2area-size: 35.37%;
        background: #EFEFEF;
        .page-content{
            position: relative;
            &:before{
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                pointer-events: none;
                z-index: -1;
                height: var(--page-bg-2area-size);
                background: #FDF0EA;
            }
        }
    }
    .page-bg-round-board{
        background: #EFEFEF;
        &:before{
            content:'';
            display: block;
            position: absolute;
            background: #FDF0EA;
            left: 0;
            top: 0;
            right: 0;
            height: 29.8%;
            box-shadow: 0 3px 8px rgba(#000, 0.12);
            border-radius: 0 0 16px 16px;
            z-index: 0;
        }
    }
    .page-bg-subnavbar-round-board{
        background: #EFEFEF;
        &:before{
            content:'';
            display: block;
            position: absolute;
            background: #FDF0EA;
            left: 0;
            top: 0;
            right: 0;
            // height 使用 f7 內建值
            height: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
            box-shadow: 0 3px 8px rgba(#000, 0.12);
            border-radius: 0 0 16px 16px;
            z-index: 0;
        }
    }

    .bg-gray{
        background: #eaeced; 
    }
    .bg-sec{
        background: $color-sec!important;
    }
    .bg-light{
        background: #FBFBFB;
    }
    .bg-dull{
        background: #F0F2F7;
    }
    .bg-danger{
        background: $color-danger!important;
    }
    // bg

    // radius
    .radius-5px{
        border-radius: 5px;
    }
    .radius-8px{
        border-radius: 8px;
    }
    .radius-10px{
        border-radius: 10px;
    }
    // radius /

    // visual-disabled
    .visual-disabled{
        opacity: 0.35;
    }
    // visual-disabled /

}
// ==== 指令 / ====

// ==== 元件 ====
.ios, .md {
    .page-inner{
        padding: 0 15px;
    }
    .page-inner-20px{
        padding-left: 20px;
        padding-right: 20px;
    }
    .page-inner-24px{
        padding-left: 24px;
        padding-right: 24px;
    }
    .page-inner-30px{
        padding-left: 30px;
        padding-right: 30px;
    }
    .page-inner-17p5px{
        padding: 0 17.5px;
    }

    // form
    form.form-list{
        margin-top: 0;
    }

    .list-v-gap-8px{
        .base-input{
            margin-bottom: 8px;
        }
    }

    .list-v-gap-13px{
        .base-input{
            margin-bottom: 13px;
        }
    }

    .list-v-gap-27px{
        .base-input{
            margin-bottom: 27px;
        }
    }

    .list-v-gap-30px{
        .base-input{
            margin-bottom: 30px;
        }
    }
    // form /

    // badge
    .badge-primary{
        background: $color-primary;
        color: #fff;
    }
    .badge-outline-primary{
        background: none;
        border:1px solid $color-primary;
        color: $color-primary;
    }
    // badge/


    // logo area
    .logo-area{
        position:relative;
        &:before{
            content:'';
            display: block;
            @include absolute(0,0,0,0);
            top: calc(-1 * var(--f7-safe-area-top));
            background: var(--color-sec);
            border-radius: 0 0 32px 32px;
            box-shadow: 0 3px 6px #0000001A;
        }
        > *{
            position: relative;
            z-index: 1;
        }
        .logo-wrap{
            width: 146px;
            margin: 0 auto;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 20px;
            background: #fff;
            position: relative;
            box-shadow: 0 3px 6px #0000001A;
            &:before{
                content:'';
                display: block;
                padding-top: 100%;
            }
            img {
                @include absolute(0,0,0,0);
                margin: auto;
                display: block;
                max-width: calc( 111 / 145 * 100%);
            }

            @include media(375){
                width: rwd-val(320px, 116px, 375px, 146px);
            }
            @include media(320){
                width: 116px;
            }
        }
        .area-title{
            text-align: center;
            font-size: 22px;
            letter-spacing: calc( 5.5 / 22 * 1em);
            font-weight: bold;
            color: rgba(#333333, 0.85);
        }
    }
    // logo area /

    // login page
    .login-page-alternative-link{
        font-size: calc( 18 / 14 * 1rem);
        letter-spacing: calc( 7.2 / 18 * 1em);
        font-weight: bold;
        display: inline-block;
        color: #666666;
        font-family: $ff-noto;
    }
    // login page /

    // forgot-page-title
    .forgot-page-title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        color: $color-primary;
        font-size: calc(18 / 14 * 1rem);
        font-weight: bold;
        letter-spacing: calc(7.2 / 18 * 1em);
        img {
            margin-right: 1rem;
        }
    }
    // forgot-page-title /

    // custom-static-navbar-subnavbar
    .custom-static-navbar-subnavbar{
        position: relative;
        padding-bottom: 15px;
        .navbar-main{
            height: calc(var(--navbar-height));
            @include flex(center,center);
        }
        &:before{
            content:'';
            display: block;
            background: #FDF0EA;
            border-radius: 0 0 24px 24px;
            box-shadow: 0 3px 12px #00000026;
            position: absolute;
            left: 0;
            top: calc(-1 * var(--f7-safe-area-top));
            right: 0;
            // height: calc(100%);
            bottom:0;
        }
        .navbar-content{
            position:relative;
            z-index: 1;
        }
        .navbar-title{
            font-family: "Noto Sans TC", sans-serif;
            font-size: 1.2857rem;
            letter-spacing: 0.1em;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-shrink: 10;
            font-weight: var(--f7-navbar-title-font-weight);
            line-height: var(--f7-navbar-title-line-height);
            text-align: var(--f7-navbar-title-text-align);
            margin-left: var(--f7-navbar-title-margin-left);
            margin-right: var(--f7-navbar-title-margin-left);
        }
    }
    // custom-static-navbar-subnavbar /

    // selection-gadge-bar
    .selection-gadge-bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .bar-title{
            flex-shrink: 0;
            font-size: calc(16 / 14 * 1rem);
            letter-spacing: calc(3.2 / 16 * 1em);
        }
        .bar-content{
        }
    
        .toolbar{
            margin-bottom: 0;
            width: 216px;
            --f7-toolbar-height: 36px;
            --f7-segmented-strong-padding: 3px;
            .tab-link-content{
                font-size: 14px;
            }
        }
    }
    // selection-gadge-bar /
}

.md{
}
// ==== 元件 / ====
