
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

@mixin base-input__custom-place-holder{

    .input-with-value ~ .custom-placeholder{
        opacity: 0;
    }

    .input-wrap{
        position: relative;
    }
    .custom-placeholder{
        font-size: var(--base-input-font-size);
        color: var(--base-input-placeholder-color);
        @include absolute(0,0,0,0);
        pointer-events: none;
        display: flex;
        align-items: center;
    }
}

@mixin base-input__sty-round-bar{
    --base-input-border-width: 2px;
    --icon-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) - 7px);
    .input-group{
        background: #fff;
        border: 2px solid #D8D8D8;
        border-radius: 28px;
        padding: 5px 32px;

        textarea, input, select{
            height: calc(var(--base-input-height) - (var(--base-input-border-width) * 2) - 10px);
        }
    }
}

@mixin base-input__sty-underline{
    --base-input-placeholder-color: #ADADAD;
    --base-input-height: 38px;

    &.is-input-focused{
        .input-group{
            @include project-border-bottom-type('solid', $color-primary);
        }
    }
    
    .input-group{
        position:relative;
        @include project-border-bottom(#ADADAD);
        // border-bottom: 2px dotted #ADADAD; 
        .input-group-prepend{
            align-self: center;
        }
    }

    .custom-placeholder-content{
        letter-spacing: 0.1em;
    }

    .required-badge{
        background: rgba($color-primary, 1);
        font-size: calc(12 / 14 * 1rem);
        letter-spacing: 0.1em;
        color: #fff;
        padding: 3px 5px;
        min-width: 45px;
        font-weight: normal;
        line-height: 1;
        margin-top: var(--badge-center-mt);
    }

    .label-txt{
        color: #333;
        font-size: calc(16 / 14 * 1rem);
        letter-spacing: 0.1em;
    }
}

.base-input {
    --base-input-height: 40px;
    --base-input-border-width: 0px;
    --base-input-icon-size: 18px;
    --base-input-badge-size: 20px;
    --base-input-font-size: var(--f7-font-size);
    --base-input-color: #000000;
    --base-input-placeholder-color: --base-input-color;

    --half-base-input-height: calc(var(--base-input-height) / 2);
    --half-base-input-icon-size: calc(var(--base-input-icon-size) / 2);

    // icon 置中用
    --half-icon-size-and-font-size-diff: calc( ( var(--base-input-icon-size) - var(--base-input-font-size) ) / 2 * 1.5);
    // icon 要置中需要的 margin值
    --icon-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) );
    // icon 置中用 /

    // badge 置中用
    --half-badge-size-and-font-size-diff: calc( ( var(--base-input-badge-size) - var(--base-input-font-size) ) / 2 * 1.5);
    // badge 要置中需要的 margin值
    --badge-center-mt: calc( var(--half-base-input-height) - var(--half-base-input-icon-size) - var(--half-icon-size-and-font-size-diff) );
    // badge 置中用 /

    margin-bottom: 20px;
    font-size: var(--f7-font-size);

    input, select, textarea{
        box-sizing: border-box;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        border: 0;
        font-size: var(--base-input-font-size);
        color: var(--base-input-color);
    }
    input, select{
        height: calc(var(--base-input-height) - (var(--base-input-border-width) * 2) );
    }
    input, textarea{
        &::placeholder{
            color: var(--base-input-placeholder-color);
        }
    }
    textarea{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    
    .input-group{
        display: flex;
    }

    .input-wrap{
        flex-grow: 1;
    }

    .input-group-prepend, .input-group-append{
        i {
            font-size: var(--base-input-icon-size);
            margin-top: var(--icon-center-mt);
        }
    }
    
    .input-group-append{
        margin-left: 10px;
    }

    @include base-input__custom-place-holder;

    &.sty-input-prevent-active {
        .input-wrap{
            position: relative;
            &:after{
                content:'';
                display: block;
                @include absolute(0,0,0,0);
            }
        }
    }
    
    &.sty-round-bar{
        @include base-input__sty-round-bar;        
    }

    &.sty-underline{
        @include base-input__sty-underline;        
    }

    &.sty-text-center{
        input, textarea, select, .custom-placeholder{
            text-align: center;
            justify-content: center;
        }
    }

    &.sty-skew-label{
        label{
            position:relative;
            padding: 0px 4px;
            padding-right: 20px;
            color: #fff;
            font-size: calc(12 / 14 * 1rem);
            letter-spacing: calc(3 / 12 * 1em);
            min-height: calc( 23 / 14 * 1rem);
            display: inline-flex;
            align-items: center;
            margin-bottom: 5px;
        }
        .skew-label{
            // font-family: $ff-msjh;
            @include absolute(0,0,-2px,0);
            svg{
                max-width: 100%;
                max-height: 100%;
            }
            // background: rgba(#333333, 0.2);
            // .skew-label-caret{
            //     width: calc( 23 / 14 * 1rem);
            //     height: calc( 23 / 14 * 1rem);
            //     &:before{
            //         content:'';
            //         display: block;
            //         padding-top: 100%;
            //     }
            //     position: absolute;
            //     left: 100%;
            //     top: 0;
            //     bottom: 0;
            //     background-repeat: no-repeat;
            //     background: linear-gradient(45deg, rgba(#333333, 0.2) 0 50%, #fff 50.1%);
            // }
        }
        .label-txt{
            position: relative;
            color: #333;
            line-height: 1;
            font-size: calc(16 / 14 * 1rem);   
            padding: 0 3px;
        }
    }

    // 星星
    .star-before, .star-after{
        position:absolute;
        color: #FF7589;
    }
    .star-before{
        right: 100%;
    }
    .star-after{
        left: 100%;
    }
    // 星星 /


    // 其他
    .tippy-box{
        background: none;
        .tippy-arrow{
            left: 50%!important;
            transform: translateX(-50%)!important;
            color: rgba($color-primary, 0.8)!important;
        }
        [data-placement^=bottom]>.tippy-arrow:before{
            border-width: 0 5px 7px;
        }
        .tippy-content{
            background:rgba($color-primary, 0.8)!important;
            border-radius: 4px;
            letter-spacing: 0.1em;
            padding: 5px 18px;
        }
    }

    .valid-icon{
        --base-input-icon-size: 19px;

        display: inline-block;
        font-size: var(--base-input-icon-size);
        color: #4de3b1;
        margin-top: var(--icon-center-mt)
    }
    // 其他 /
    
}

.error-msg {
    padding-top: 5px;
    display: inline-block;
    color: $color-danger;
    font-size: 1em;
}
