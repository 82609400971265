
@import "@design";

.map-panel{
    --map-height: 100vh;
    .map{
        height: var(--map-height)!important;
    }

    .map-custom-control{
        &.my-location{
            width: 40px;
            height: 40px;
            background: #fff;

            position: absolute;
            right: 10px;
            bottom: calc(var(--f7-toolbar-height) + var(--f7-safe-area-bottom));
            @include flex(center, center);
            box-shadow: rgba(#000, 0.3) 0px 1px 4px -1px;
            z-index: 5;
            i {
                font-size: 22px;
            }
        }
    }
}


