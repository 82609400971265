
@import "@design";



// status-popup
.handicapped-status-popup {
  .dialog-popup-inner {
    padding: 10px !important;
  }

  &__status-name {
    color: #000000;
    font-size: 1.3rem;
  }

  &__status-text {
    font-size: calc(24 / 14 * 1rem);
    font-weight: 700;
    margin-bottom: 13px;
  }

  &__status-code-1 {
    // 驗證中
    color: #FD8908;
  }

  &__status-code-2 {
    // 未通過
    color: #FD3408;
  }

  &__des {
    margin-bottom: 20px;
    color: #000000;
    line-height: calc(20 / 14 * 1rem);
  }

  &__checkbox-area {
    margin-bottom: 10px;
    color: #A4A4A4;
    font-size: 1rem;
    font-family: $ff-msjh;
  }

  &__btn-group {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;

    .button {}

    .btn-setting {
      color: #666666;
      text-align: left;
      width: auto;
      padding-left: 0;
      font-weight: 700;
    }

    .btn-close {
      width: 94px;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.16);
    }
  }
}

// status-popup /
