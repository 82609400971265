
@import "@design";

.user-media{
    background:#fff;
    border: 2px solid #D8D8D8;
    border-radius: 50%;
    position:relative;
    width: 85px;
    box-sizing: border-box;
    &:before{
        content:'';
        display:block;
        padding-top: 100%;
    }
    .media-inner{
        @include absolute(6px, 6px, 6px, 6px);
        border-radius: inherit;
        overflow: hidden;
    }
    img {
        @include absolute(0,0,0,0);
        margin:auto;
        width: 100%;
    }
}


