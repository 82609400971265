
@import "@design";

.popup.sty-modal.sty-notify-use.notify-use-popup{
    background: rgba(0, 0, 0, 0.61);
    backdrop-filter: blur(15px);

    &.modal-in{
        .page-content{
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(1);
        }
    }

    .popup-dialog-head{
        align-items: flex-start;
        .left{
            flex-grow: 1;
            font-size: calc(20 / 14 * 1rem);
            font-weight: bold;
            @include letter-spacing-center(calc(5 / 20 * 1em));
            color: var(--color-theme);
            padding-top: 0.1em;
        }
        .title{
            display: none;
        }
        .right{
            a {
                display: block;
                line-height: 1;
            }
            padding-top: 0.1em;
        }
    }

    .dialog-popup-inner{
        padding-top: 7px;
    }

    .page-content{
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(1.185);
    }

    .popup-time-display{
        color: #adadad;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        i {
            margin-right: 0.3em;
            margin-top: 0.1em;
        }
    }

    .dialog-popup-des{
        font-size: 18px;
    }

    .dialog-popup-btn{
        display: inline-flex;
        width: auto;
        min-width: 190px;
        max-width: 100%;
        .btn-inner{
            width: 100%;
            margin: 0 7px;
        }
    }

    .dialog-popup-btn-2side{
        .btn-inner{
            justify-content: space-between;
        }
    }
}

.notify-use-popup-backdrop{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 13000;
    visibility: hidden;
    opacity: 0;
    transition-duration: 400ms;
    z-index: 10500;

    &.backdrop-in{
        backdrop-filter: blur(15px);
        background: rgba(0, 0, 0, 0.61);
        visibility: visible;
        opacity: 1;
    }
}

