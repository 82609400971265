@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bhtci8');
  src:  url('fonts/icomoon.eot?bhtci8#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bhtci8') format('truetype'),
    url('fonts/icomoon.woff?bhtci8') format('woff'),
    url('fonts/icomoon.svg?bhtci8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-gps_fixed:before {
  content: "\e923";
}
.icon-time:before {
  content: "\e922";
}
.icon-question:before {
  content: "\e918";
}
.icon-seman:before {
  content: "\e919";
}
.icon-chart:before {
  content: "\e91a";
}
.icon-judge:before {
  content: "\e91b";
}
.icon-target:before {
  content: "\e91c";
}
.icon-love_hand:before {
  content: "\e91d";
}
.icon-sheild .path1:before {
  content: "\e91e";
  color: rgb(0, 0, 0);
}
.icon-sheild .path2:before {
  content: "\e91f";
  margin-left: -0.8828125em;
  color: rgb(255, 255, 255);
}
.icon-line:before {
  content: "\e920";
}
.icon-setting:before {
  content: "\e921";
}
.icon-arrow-lg-right:before {
  content: "\e917";
}
.icon-sections:before {
  content: "\e916";
}
.icon-clock:before {
  content: "\e914";
}
.icon-flag:before {
  content: "\e915";
}
.icon-arrow-lg-left:before {
  content: "\e90f";
}
.icon-cancel:before {
  content: "\e910";
}
.icon-gps:before {
  content: "\e911";
}
.icon-msg:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-yes:before {
  content: "\e90e";
}
.icon-chevron-left:before {
  content: "\e90c";
}
.icon-chevron-right:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e907";
}
.icon-group:before {
  content: "\e908";
}
.icon-heart:before {
  content: "\e909";
}
.icon-wheelchair:before {
  content: "\e90a";
}
.icon-wheelchair2:before {
  content: "\e90b";
}
.icon-bars:before {
  content: "\e903";
}
.icon-bell2:before {
  content: "\e904";
}
.icon-house:before {
  content: "\e905";
}
.icon-man:before {
  content: "\e906";
}
.icon-star:before {
  content: "\e902";
}
.icon-bell:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e901";
}
