
@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";

.ios, .md {
    .navbar {
        .base-nav-logo{
            width: 54px;
            height: 54px;
            box-shadow: 0 3px 6px #00000014;
            background: #fff;
            border-radius: 50%;
            margin-top: 6px;
            @include flex(center,center);
            img {
                height: 41.51px;
            }
        }
    }
}

